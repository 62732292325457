import React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import {
    mapEdgesToNodes,
    filterOutDocsWithoutSlugs,
    filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import PortableText from '../components/portableText'

export const query = graphql`
  query TherapistPageQuery($id: String, $trainingIds: [String]) {
	page: sanityTherapistPage(id: {eq: $id}) {
        id
        slug {
          current
        }
        therapist {
          firstName
          function
          lastName
          location
          phone
          specialisations
          website
          profilePhoto {
            asset {
              url
              fluid {
				...GatsbySanityImageFluid
			  }
            }
          }
          email
          _rawDescription
          _rawBio,
          trainings {
            training {
                name,
                id
            }
          }
        }
        showBanner
        seoTitle
        seoKeywords
        seoDescription
        hideOverlay
        bannerTitle
        bannerSubTitle
        bannerImage {
          asset {
            url
            fluid {
				...GatsbySanityImageFluid
			}
          }
        }
        bannerCta {
          label
          url
        }
      },
      trainingPages: allSanityTrainingPage(filter: {
        training: {
          id:{
            in: $trainingIds
          }
        }
      }) {
        edges {
          node {
            slug {
                current
            }
            bannerImage {
                asset {
                    url
                    fluid {
                        ...GatsbySanityImageFluid
                    }
                }
            }
            previewImage {
                asset {
                    url
                    fluid {
                        ...GatsbySanityImageFluid
                    }
                }
            }
            training {
                name
                description
                price
                unit
            }
          }
        }
      }
  }
`

const TherapistPage = ({ data = {}, errors }) => {

    if (errors) {
        return (
            <Layout>
                <GraphQLErrorList errors={errors} />
            </Layout>
        )
    }

    const page = data.page || {}
    const trainingPages = (data.trainingPages || {}).edges || []

    const {
        therapist = {}
    } = page

    const {
        firstName,
        function: _function,
        lastName,
        location,
        phone,
        specialisations = [],
        website,
        profilePhoto = {},
        email,
        _rawDescription,
        _rawBio,
        trainings: []
    } = therapist

    const { asset: profileAsset = {} } = profilePhoto
    const { url: profileImageAssetUrl, fluid: profileImageAssetFluid } = profileAsset

    if (!data.page) {
        throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
        )
    }

    return (
        <Layout page={page} >
            <Container>
                <section className="py-16 px-2 md:px-4 4xl:px-0 bg-white">
                    <div className="flex flex-col-reverse md:flex-row max-w-6xl mx-auto px-4">
                        <div className="flex-2 mt-16 md:mt-0">
                            <h2 className="font-roboto-slab text-5xl text-gray-900">
                                <span className="leading-tighter">{firstName + " " + lastName}</span>
                                <span className="block -mt-1 text-gray-700 text-2xl">{_function}</span>
                            </h2>
                            <p className="mt-6 text-xl text-gray-500 tracking-wide">
                                <PortableText blocks={_rawDescription} />
                                {/* Dit is een korte omschrijving over Solange. Meer informatie wordt gegeven als je hierop klikt. */}
                            </p>
                            <h3 className="font-roboto-slab mt-16 text-3xl text-gray-800">
                                Biografie
                            </h3>
                            <p className="mt-2 text-gray-600 text-lg leading-relaxed tracking-wide">
                                <PortableText blocks={_rawBio} />
                                {/* Ik kom uit het zakenleven maar merkte dat mijn hart ligt bij het coachen van mensen. Na mijn opleiding tot HSP coach en EMDR-therapeut zag ik dat er bij Angsthelden erg mooie resultaten werden behaald met hun methodes. Na een opleiding tot angstcoach ben ik voor Angsthelden aan de slag gegaan. Zo fijn om mensen te zien opbloeien na vaak een jarenlange worsteling! Tot ziens in Elst! */}
                            </p>
                        </div>
                        <div className="flex-1 flex flex-col sm:flex-row md:flex-col ml-0 md:ml-16">
                            <div className="flex-1">
                                <Img className="w-full h-64 bg-gray-200 border-0 rounded-lg object-cover shadow-lg" fluid={profileImageAssetFluid} />
                                <Link className="block mt-4 px-8 py-3 text-lg text-center text-white rounded-lg bg-orange-400 uppercase tracking-widest" to={ "/contact" }>Boek een afspraak</Link>
                            </div>
                            <div className="flex-1 sm:px-8 md:p-0">
                                <div className="mt-8 sm:mt-0 md:mt-8">
                                    <h4 className="font-roboto-slab text-xl text-orange-400">Regio</h4>
                                    <span className="block mt-1 text-gray-600 font-bold tracking-wide">{location}</span>
                                </div>
                                {specialisations.length > 0 && <div className="mt-8">
                                    <h4 className="font-roboto-slab text-xl text-orange-400">Specialisatie</h4>
                                    <span className="block mt-1 text-gray-600 font-bold tracking-wide">{specialisations.join(", ")}</span>
                                </div>}
                            </div>
                        </div>
                    </div>
                </section>
                {trainingPages.length > 0 && <section className="py-16 px-2 md:px-4 4xl:px-0 bg-gray-50">
                    <div className="max-w-6xl mx-auto px-4">
                        <h3 className="font-roboto-slab text-3xl text-gray-800">
                            Behandelingen gegeven door {firstName}
                        </h3>
                        <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 max-w-6xl mx-auto">

                            {
                                trainingPages.map(({node: page}) => {
                                    const { id, previewImage: bannerImage = {}, slug = {}, training = {} } = page
                                    const { asset = {} } = bannerImage || {}
                                    const { url, fluid } = asset
                                    const { current } = slug
                                    const { name, description, price, unit } = training
                                    return (
                                        <Link to={current} className="flex flex-col rounded-lg overflow-hidden shadow-lg">
                                            <div className="flex-shrink-0 h-48 bg-white">
                                                <Img className="h-48 w-full object-cover" fluid={fluid} alt="" />
                                            </div>
                                            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                                <div className="flex-1">
                                                    <p className="text-sm leading-5 font-medium text-orange-400">
                                                        {price} {unit}
                                                    </p>
                                                    <div href="#" className="block">
                                                        <h3 className="font-roboto-slab  text-xl leading-7 font-semibold text-gray-900">
                                                            {name}
                                                        </h3>
                                                        <p className="mt-3 text-base leading-6 text-gray-500">
                                                            {/* <PortableText blocks={_rawDescription} /> */}
                                                            {description}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="mt-4 text-right text-orange-400 font-semibold">Lees meer &rarr;</div>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>}
                <section className="py-16 px-4 4xl:px-0 bg-orange-400">
                    <div className="sm:flex flex-row justify-between items-center max-w-6xl mx-auto px-4">
                        <div>
                            <h2 className="font-roboto-slab text-white text-2xl md:text-4xl">Partner worden?</h2>
                            <p className="text-xl text-orange-100">Partners genieten van extra voordelen.</p>
                        </div>
                        <div className="mt-8 sm:mt-0">
                            <Link to="/partners" className="block px-8 py-4 text-orange-400 text-lg rounded-lg bg-white rounded-lg uppercase tracking-widest">Meer informatie</Link>
                        </div>
                    </div>
                </section>
            </Container>
        </Layout>
    )
}

export default TherapistPage